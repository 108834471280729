/* floating_at_bottom */
import * as React from "react";
import { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const BottomBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem("userAgreed")) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem("userAgreed", "true");
    setShowBanner(false);
  };

  return (
    <>
      {showBanner ? (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-50">
          <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8 mr-24">
            <div className="p-2 rounded-lg bg-black shadow-lg sm:p-3">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  {/* <span className="flex p-2 rounded-lg bg-sky-800">
                <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span> */}
                  <p className="ml-3 text-white text-sm">
                    <span className="inline">
                      Sitemiz size daha iyi hizmet sunabilmek için, çerezler
                      kullanır. Devam ederek, çerez kullanımını kabul etmiş
                      olursunuz. Detaylı bilgiye{" "}
                      <a
                        className="text-sky-500 hover:text-sky-400 whitespace-nowrap"
                        href="/cookie"
                      >
                        Çerez Politikası
                      </a>{" "}
                      sayfamızdan erişebilirsiniz.
                    </span>
                  </p>
                </div>
                {/* <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <a href="#" className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-sky-600 bg-white hover:bg-sky-50">
                Learn more
              </a>
            </div> */}
                <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                  <button
                    type="button"
                    className="-mr-1 flex p-2 rounded-md hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={handleClick}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default BottomBanner;
