import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BottomBanner from "../components/bottomBanner";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const seoData = {
    title: "ONAYLARIM",
    description: "Tüm belgelerinizi elektronik ortamda imzalayın.",
    slug: "",
};

const IndexPage = () => {
    const { t } = useTranslation();
    const allfeatures = t("allFetaures", { returnObjects: true });
    return (
        <Layout pageTitle="Home Page">
            <Seo title={seoData.title} description={seoData.description || seoData.excerpt || "nothin’"} pathname={seoData.slug} article />
            <div className="grid sm:grid-cols-12 mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl ">
                <div className="col-span-2 py-6 sm:py-8 lg:py-12">
                    {allfeatures.map((feature, index) => (
                        <div className="my-2">
                            <a href="{'#id' + index}" className="text-lg font-medium text-gray-900 tracking-tight text-left">
                                {feature.title}
                            </a>
                        </div>
                    ))}
                </div>
                <div className="col-span-10 ">
                    {allfeatures.map((feature) => (
                        <div className="relative bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                                <p id="{'#id' + index}" className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl text-left">
                                    {feature.title}
                                </p>

                                <div className="mt-2">
                                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                        {feature.items.map((feature) => (
                                            <div key={feature.name} className="pt-6">
                                                <div className="flow-root bg-gray-100 rounded-lg px-6 pb-8">
                                                    <div className="mt-6">
                                                        <h3 className="mt-8 text-2xl font-medium text-gray-900 tracking-tight text-left">{feature.name}</h3>
                                                        <p className="mt-5 text-base text-gray-500 text-left">{feature.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <BottomBanner></BottomBanner>
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

// export const query = graphql`
//     query ($language: String!) {
//         locales: allLocale(filter: { ns: { in: ["common"] }, language: { eq: $language } }) {
//             edges {
//                 node {
//                     ns
//                     data
//                     language
//                 }
//             }
//         }
//     }
// `;
